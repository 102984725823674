/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { electrical } from "../../modules/auth/core/_requests";
import SwiperCommon from "../../components/swiper-custom/SwiperCommon";
import {
  getElectrical,
  setElectrical,
} from "../../modules/auth/core/InventoryHelpers";
import { KTSVG } from "../../../_metronic/helpers";

type Props = {
  className: string;
  page?: string;
  invoiceType?: string;
};

let total: number = 0;

const ElectricalPage: React.FC<Props> = ({ className, page, invoiceType }) => {
  const [electricalTable, setElectricalTable]: any = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceTypeMode, setInvoiceTypeMode]: any =
    React.useState(invoiceType);

  const fetchElectricalData = async () => {
    let electricalTable = await electrical();
    setElectrical(electricalTable.data.result);
    let localElectrical = getElectrical();
    setElectricalTable(localElectrical);
  };

  useEffect(() => {
    let localElectrical = getElectrical();
    if (invoiceTypeMode === "edit") {
      let localStorageElectricals: any = localStorage.getItem("electricals");
      let tempSavedElectricals: any = JSON.parse(localStorageElectricals);
      total = tempSavedElectricals?.total;
      setElectricalTable(tempSavedElectricals?.data);
    } else {
      if (!localElectrical) {
        fetchElectricalData();
      } else {
        setElectricalTable(localElectrical);
      }
    }
  }, []); // eslint-disable-line

  const units = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let tempTotal: number = 0;

  const handleChange = (unitCost: any, event: any, index: any, data: any) => {
    let clone = [...electricalTable];
    let obj = clone[index];
    let unit = Number(event.target.value);
    const cost = unitCost * unit;
    obj.cost = cost;
    obj.unit = unit;
    clone[index] = obj;
    setElectricalTable([...clone]);

    // let selectedItem = []

    // for (let i = 0; i < electricalTable.length; i++) {
    //   if ((electricalTable[i].cost && electricalTable[i].cost > 0) || (electricalTable[i].type === "Undercabinet - this was included" && electricalTable[i].unit > 0)) {
    //     selectedItem.push(electricalTable[i])
    //     let localstorageData = {
    //       total: total,
    //       data: selectedItem
    //     }
    //     localStorage.setItem('electricals', JSON.stringify(localstorageData));
    //   }
    // }

    let localstorageData = {
      total: total,
      data: electricalTable,
    };
    localStorage.setItem("electricals", JSON.stringify(localstorageData));

    let localStorageElectricals: any = localStorage.getItem("electricals");
    let tempSavedElectricals: any = JSON.parse(localStorageElectricals);
    let savedElectricals: any = tempSavedElectricals.data;
    for (let i = 0; i < savedElectricals.length; i++) {
      if (savedElectricals[i].cost) {
        tempTotal = tempTotal + savedElectricals[i].cost;
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedElectricals,
      };
      localStorage.setItem("electricals", JSON.stringify(localstorageData));
    }

    if (unit === 0) {
      const index = savedElectricals.findIndex((saved: any) => {
        return saved._id === data._id;
      });
      if (index > -1) {
        savedElectricals.splice(index, 1);
      }

      let localstorageData = {
        total: total,
        data: savedElectricals,
      };

      localStorage.setItem("electricals", JSON.stringify(localstorageData));
    }
  };

  // Inventory Slider Module : Starts

  const photos = [
    "/media/images/slider/image1.jpg",
    "/media/images/slider/image2.jpg",
    "/media/images/slider/image3.jpg",
    "/media/images/slider/image4.jpg",
    "/media/images/slider/image5.jpg",
    "/media/images/slider/image6.jpg",
  ];

  const [openSlider, setOpenSlider] = useState(false);

  const openSlide = () => {
    setOpenSlider(true);
  };

  const CloseSlider = () => {
    setOpenSlider(false);
  };

  // Inventory Slider Module : Ends

  // Search Function : Begins

  const [query, setQuery] = useState("");

  // const searchTable = (data: any) => {
  //   return data.filter((data: { type: string; }) =>
  //     data.type.toLowerCase().includes(query)
  //   );
  // };

  // Search Function : Ends

  return (
    <div
      className={`card ${className}`}
      style={{ maxWidth: "1600px !important" }}
    >
      {/* begin::Header */}
      {page === "invoice" && (
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Electrical</span>
          </h3>
        </div>
      )}
      {/* Open SLider on thumnail click : Starts */}
      {openSlider && (
        <SwiperCommon imagearray={photos} CloseSlider={CloseSlider} />
      )}
      {/* Open SLider on thumnail click : Ends */}
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin:: Table Search Box */}
        {page === "invoice" ? null : (
          <div className="card-title">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative my-1 pt-10 pb-10">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                placeholder="Search electrical"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        )}
        {/* end:: Table Search Box */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}

          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-120px">Images</th>
                <th className="min-w-120px">Type</th>
                <th className="min-w-120px">Unit Cost $</th>

                {page === "invoice" && (
                  <>
                    <th className="min-w-120px">Units</th>
                    <th className="min-w-120px">Cost</th>
                  </>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {electricalTable?.length &&
                electricalTable
                  ?.filter((data: { type: string }) =>
                    data.type.toLowerCase().includes(query)
                  )
                  .map((data: any, index: any) => (
                    <tr key={data?._id}>
                      <td>
                        <img
                          src={photos[0]}
                          onClick={openSlide}
                          style={{
                            width: "60px",
                            height: "50px",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                      <td>
                        <a
                          // href=""
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          {data?.type}
                        </a>
                      </td>
                      <td className="text-start">
                        <a
                          // href=""
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          ${data?.unit_cost}
                        </a>
                      </td>

                      {page === "invoice" && (
                        <>
                          <td className="text-start">
                            <select
                              className="form-select form-select-solid"
                              data-kt-select2="true"
                              data-placeholder="Direction"
                              data-allow-clear="true"
                              // defaultValue={data?.direction[0]}
                              onChange={(event) =>
                                handleChange(
                                  data?.unit_cost,
                                  event,
                                  index,
                                  data
                                )
                              }
                              value={data?.cost && data?.unit ? data?.unit : 0}
                            >
                              {units.map((unit, index) => (
                                <option key={index} value={unit}>
                                  {unit}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="text-start">
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              ${data?.cost || 0}
                            </a>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}

              {page === "invoice" && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: 600 }}>Total</td>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        ${total?.toFixed(2) || 0}
                      </a>
                    </td>
                  </tr>
                  {/* <tr style={{ marginTop: "10px" }}>
                    <td colSpan={2}>
                      <FormInput type='text' className='form-control form-control-solid' placeholder='Please enter comment here' onChange={(e) => handleComment(e.target.value)} />
                    </td>
                  </tr> */}
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { ElectricalPage };
